
.paper-detail {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    height: 100%;
    overflow: hidden;
    .content {
        flex: 1;
        overflow: hidden;
    }
}
.paper-detail {
    ::v-deep .el-tabs .is-active {
        transition: all 0.3s;
        background-color: #e7f6ef;
    }
}
